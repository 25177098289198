/**
 * English translation resources.
 * Each key corresponds to a phrase, and its value is the translated phrase in English.
 */
const en = {
  translation: {
    /**
     * Side, Top and mobile navigation bar
     */
    Collapse: 'Collapse',
    Home: 'Home',
    News: 'News',
    AboutTheGame: 'About the Game',
    Faq: 'FAQ',
    Contact: 'Contact',
    AboutUs: 'About Us',
    Language: 'Language',
    DarkLightMode: 'Dark / Light Mode',
    PreviousSlide: 'Previous Slide',
    NextSlide: 'Next Slide',
    ChangeLanguage: 'Change Language',
    ToggleDarkLightMode: 'Toggle Dark Light Mode',

    /**
     * Home
     */
    Heading0: 'Unravel Your Legacy on Outcast Isle...',
    Heading1: '…Inherit the mystery of a wizard or a knight…',
    Heading2: '…Forge your destiny in a world divided…',
    Heading3: '…Your journey starts here, where secrets and choices await…',
    Subtitle0: 'Embark on an Epic Quest to Reclaim the Forgotten Lands!',
    Subtitle1: 'Unlock the Secrets of a Home Filled with Mysteries!',
    Subtitle2: 'Explore the Shadows to Illuminate the Truth!',
    Subtitle3: 'Dare to Uncover Ancient Secrets Buried Beneath!',
    Subtitle4: "Grind Your Way to Glory in the Old Mill's Tale!",
    Subtitle5: 'Delve into the Depths of the Mysterious Mine!',
    Subtitle6: 'Solve the Riddles of the Rainy Night and Unveil Hidden Paths!',
    Subtitle7:
      'Embark on a Journey Through Enchanted Woods and Discover Ancient Wisdom!',
    Subtitle8: 'Set Sail for Adventure and Navigate the Seas of Fate!',

    /**
     * Troubleshooting Page
     */
    Troubleshooting: 'Troubleshooting',
    TroubleshootingHeading0: 'No guides available yet',
    TroubleshootingText0:
      'Since there is no official release and beta tester has not played the game yet, there are no guides',

    /**
     * News Page
     */
    English: 'English',
    German: 'German',

    /**
     * News Page
     */
    NewsHeading0: 'Website Launch Announcement',
    NewsContent0: `We are thrilled to announce the launch of our game's website!\n
      Stay tuned for more updates.`,
    NewsHeading1: 'Explore the Golden Majesty of Auroria!',
    NewsContent1: `Discover the heartland of the Celestine Dominion—Auroria! Renowned for its sprawling golden fields and majestic cities, Auroria is a land where prosperity and beauty intertwine.\n
      As you wander its lush landscapes, you'll feel the pulse of a world brimming with opportunity and intrigue.\n
      The groundwork for Auroria's breathtaking map is complete, and we can’t wait to fine-tune its details to make your journey truly unforgettable.\n
      Will you uncover the secrets of its prosperity or shape its destiny for generations to come? Your adventure awaits in Auroria!`,
    NewsHeading2: 'Verdante Where Nature Thrives and Wonders Abound',
    NewsContent2: `Step into the enchanting world of Verdante, a country celebrated for its vast, emerald forests and agricultural marvels.\n
      As a cornerstone of the Dominion’s wealth, Verdante offers a serene yet vibrant escape for adventurers seeking to explore the wonders of nature.\n
      The initial map design is ready, with intricate fine-tuning on the horizon to bring this verdant paradise to life.\n
      Will you roam its deep forests, build alliances among its communities, or unlock its hidden treasures? Verdante is calling—answer the call of adventure!`,

    /**
     * About the Game
     */
    GameDescriptionHeading: `Game Description`,
    GameDescriptionSubHeading0: `Discover Your Legacy, Shape Your Destiny`,
    GameDescriptionText0: `In “GAME_TITLE”, embark on an epic journey that transcends time and tradition.\n
    Set in the vividly imagined world of [Game World Name], you play as the child of a forbidden union between a noble wizard and a valiant knight.\n
    Exiled to the remote Outcast Isle, your adventure begins in a land steeped in mystery, magic, and legacy.`,

    GameDescriptionSubHeading1: `A Tale of Two Worlds`,
    GameDescriptionText1: `Unravel the story of your parents – a mother from the prestigious wizard family serving the Celestine Dominion and a father, a knight of humble origins, now part of the Onyx Coalition.\n
    Your existence, born from love that defied social norms, sets you on a path fraught with intrigue, conflict, and choices that could alter the course of history.`,

    GameDescriptionSubHeading2: `Explore Outcast Isle and Beyond`,
    GameDescriptionText2: `Your journey starts on Outcast Isle, a forgotten land where exiles and secrets lurk in every shadow.\n
    Explore mysterious caves, engage with local villagers, and uncover the hidden truths that lie within your family's past.\n
    Navigate through treacherous landscapes, from the lush fields of the Dominion to the harsh terrains of the Coalition.`,

    GameDescriptionSubHeading3: `Master the Art of Combat and Magic`,
    GameDescriptionText3: `Choose your path as a knight or a wizard, each offering a unique gameplay experience.\n
    Train in the art of combat, perfecting swordplay and strategy, or delve into the arcane mysteries as a wielder of magic.\n
    Equip yourself with weapons and spells that carry the essence of your lineage.`,

    GameDescriptionSubHeading4: `Embark on Quests of Discovery and Valor`,
    GameDescriptionText4: `Engage in a variety of quests that will test your skills, resolve, and intellect.\n
    From battling creatures to decoding ancient puzzles, each quest brings you closer to understanding the complex dynamics of the world you inhabit.`,

    GameDescriptionSubHeading5: `Make Decisions That Matter`,
    GameDescriptionText5: `Your choices in “GAME_TITLE” shape your journey and the world around you.\n
    Align with factions, decide the fate of characters you meet, and uncover multiple endings based on your actions.\n
    Will you bring unity to the divided lands or lead them into further turmoil?`,

    GameDescriptionSubHeading6: `A World Awaiting Your Footprints:`,
    GameDescriptionText6: `“GAME_TITLE” invites players into a realm where myths come alive, and legends are born.\n
    With stunning visuals, captivating storytelling, and immersive gameplay, your adventure in [Game World Name] is only limited by your imagination.\n
    Are you ready to uncover your past and forge your future?\n
    Your legacy awaits in “GAME_TITLE”.`,

    /**
     * Storyline and Setting
     */
    Storyline: `Storyline and Setting`,
    StorylineHeading: `A World Divided, A Destiny Unfolding`,
    StorylineText: `In the realm of [Game World Name], the Celestine Dominion and the Onyx Coalition stand on the brink of conflict, their histories intertwined yet divergent.\n
    The Dominion, a land of magical nobility and order, faces the rising dissent of the Coalition, a conglomeration of nations rich in resources yet burdened by struggle.\n
    Between these mighty factions lies the neutral Gilded Enclave of Dwarrowdelf, whose dwarven inhabitants forge the finest weapons and jewels, their allegiance a mystery.\n
    You begin your journey on Outcast Isle, an isolated land where the exiled and forgotten converge.\n
    Here, amidst whispers of rebellion and tales of ancient magic, you uncover the legacy of your parents – a forbidden union between a noble wizard and a humble knight.\n
    This isle, with its hidden secrets and untamed nature, serves as the crucible for your destiny.\n
    Will you rise as a savior or herald the downfall of an empire? The path you choose will echo through the annals of history.`,

    /**
     * Key Features
     */
    KeyFeatures: `Key Features`,
    KeyFeatureSubHeading0: `Choose Your Class`,
    KeyFeatureText0: `Embrace the steel of a Knight or the arcane mysteries of a Wizard.\n
    Each class offers a unique playstyle and abilities.`,

    KeyFeatureSubHeading1: `Dynamic Storytelling`,
    KeyFeatureText1: `Your choices shape the narrative, leading to different outcomes and alliances.\n
    Experience a story that adapts to your decisions.`,

    KeyFeatureSubHeading2: `Rich World Exploration`,
    KeyFeatureText2: `Discover the diverse landscapes of [Game World Name], from the lush fields of the Dominion to the rugged terrains of the Coalition, and the mysterious heights of Dwarrowdelf.`,

    KeyFeatureSubHeading3: `Engaging Combat and Magic System`,
    KeyFeatureText3: `Master a combat system that blends skillful swordplay with powerful spells.\n
    Customize your approach to battles and challenges.`,

    KeyFeatureSubHeading4: `Crafting and Resource Management`,
    KeyFeatureText4: `Gather resources, craft potent items, and manage your inventory to prepare for the adventures ahead.`,

    KeyFeatureSubHeading5: `Intriguing Quests and Puzzles`,
    KeyFeatureText5: `Solve ancient puzzles, uncover hidden chambers, and complete quests that reveal the deep lore of the world.`,

    KeyFeatureSubHeading6: `Meet Memorable Characters`,
    KeyFeatureText6: `Interact with a cast of characters rich in backstory and complexity.\n
    Build relationships that impact your journey.`,

    /**
     * Trailer
     */
    Trailer: `Trailer`,
    TrailerText0: `There is currently no trailer available yet, but there will be a couple of them in the near future.`,

    /**
     * Character Profiles
     */
    CharacterProfiles: `Character Profiles`,
    CharacterName0: `The Protagonist`,
    CharacterText0: `As the child of a noble wizard and a brave knight, you stand at the crossroads of destiny.\n
    Your journey from Outcast Isle to the heart of the Dominion and beyond will reveal your true purpose.`,

    CharacterName1: `Mother - "MOTHER_NAME"`,
    CharacterText1: `A wise and powerful wizard of the Celestine Dominion, now living in exile.\n
    Her past is shrouded in mystery and magic.`,

    CharacterName2: `Father - "FATHER_NAME"`,
    CharacterText2: `A knight of humble origin, whose valor and strength are matched only by his devotion to his family.\n
    His legacy is a testament to courage and resilience.`,

    Upwards: 'Upwards',
    SysReq: 'System Requirements',
    OrNewer: 'or newer',
    SysReqMin: 'Minimum',
    SysReqRec: 'Recommended',
    AvailableSpace: 'available space',

    OS: 'Operating System',
    Processor: 'Processor',
    Memory: 'Memory',
    Graphics: 'Graphics',
    Storage: 'Storage',

    /**
     * FAQ
     */
    All: 'All',
    General: 'General',
    Billing: 'Billing',
    Category: 'Category',
    SearchFaqs: 'Search for FAQs',

    WhatIsIt: 'What is "GAME_TITLE"',
    WhatIsItAnswer: '"GAME_TITLE" is a 2D fantasy RPG for Mac, Linux and Windows.',

    WhoMadeIt: 'Who made the game?',
    WhoMadeItAnswer:
      'The game "GAME_TITLE" is still in development and it is complately planed and developed solo by K1ngAndu1n (Peter Stueben) so far. I started developing the game in the mids of 2022.',

    Publisher: 'Who is the publisher?',
    PublisherAnswer:
      '"GAME_TITLE" is self-published by K1ngAndu1n (Peter Stueben) and will probably be available on the following platforms Mac, Linux and Windows.',

    WhereToAcquire: 'Where to acquire the game?',
    WhereToAcquireAnswer:
      'The game can be acquired on Steam as soon as the development process is finished.',

    WhatLang: 'What languages does the game support',
    WhatLangAnswer:
      'The game currently supports English and German but there will be much more languages when the first release comes out.',

    WhatCreatedWith: 'What was the game created with?',
    WhatCreatedWithAnswer:
      'The game was programmed in Python. The main framwork which was used is called Pygame. Additionally, I used Tiled for the creation of the maps and for editing the sprites I used Aseperite',

    WhichPatternsArchitectures: 'What patterns and architectures were applied?',
    WhichPatternsArchitecturesAnswer:
      'As game architecture the MVC (Model View Controller) pattern was used. In order to solve logic issues, the following patterms were applied, Flyweight, Factory, Observer, State, Command and many more.',

    /**
     * Contact
     */
    GetInTouch: 'Get in Touch',
    TroubleShootingLink: 'Troubleshooting Guide',
    CopyUrl: 'Copy URL',
    CopyEmail: 'Copy Email',
    EmailUsAt: 'Email our support (not done yet)',
    FollowUsOnInsta: 'Follow us on Insta (not done yet)',
    FollowUsOnFb: 'Like us on Facebook (not done yet)',

    /**
     * About Us
     */
    AboutMe: 'About Me',
    IntroText: `Hi,\n
    I work as a Solutions Architect and I'm a passionate game developer.\n
    I've combined my professional skills and love for gaming to create [GAME_TITLE].\n
    In this project, I've taken on roles such as Webdesigner, Engineer, Architect, Developer, Graphic Designer, Game Designer, etc.\n
    I'm driven by my deep-seated hobby in coding, a relentless curiosity for solving complex issues, and an eagerness for new challenges.\n
    The exhilarating feeling of conquering uncharted territories in expertise fuels my enthusiasm.\n
    This game is a reflection of my passion, and I'm excited to share it with you.\n
    Connect with me on:`,

    JourneyGameDevelopmentHeading: 'My Journey in Game Development',
    TheBeginningHeading: 'The Beginning',
    JourneyGameDevelopmentTextBeginning: `My foray into the world of game development truly began during my college years.\n
    It was a time of discovery and inspiration, largely influenced by a remarkable professor who was not only deeply immersed in game development\n
    but had also created several games himself. He was more than just a teacher to me; he was a mentor who guided me into this fascinating world.`,

    JourneyGameDevHeading0: 'My Early Creations',
    JourneyGameDevText0: `Starting out, I delved into the realm of text-based console games using Python.\n
    These simple and easy games were my initial steps into programming and game design.\n
    I vividly remember the thrill of bringing those lines of code to life, transforming them into interactive stories and challenges.`,

    JourneyGameDevHeading1: 'The Evolution',
    JourneyGameDevText1: `Gradually, I transitioned to more complex creations, including graphical games like a basic version of ping pong.\n
    This phase was crucial as it marked my first foray into integrating visuals and interactivity in a more sophisticated manner.`,

    JourneyGameDevHeading2: 'Learning Through Trial and Error',
    JourneyGameDevText2: `As I became more ambitious, I began developing my own game concepts.\n
    This period was riddled with trials and errors due to my limited knowledge at the time.\n
    Numerous attempts and failures taught me valuable lessons in game development and resilience.`,

    JourneyGameDevHeading3: 'A Milestone Achievement',
    JourneyGameDevText3: `My first significant success was recreating the popular game Moorhuhn.\n
    This project was a turning point, as it combined all the skills I had acquired and gave me the confidence to take on even more challenging projects.`,

    JourneyGameDevHeading4: 'Current Endeavors',
    JourneyGameDevText4: `Today, I am fully engaged in my most exciting project yet.\n
    This ongoing work represents not just a culmination of my skills and experiences but also a bold step into new, unexplored territories in game development.\n
    I believe this project will be a groundbreaking addition to my portfolio and a testament to my growth in this dynamic field.`,

    OvercomingChallengesHeading: 'Overcoming Challenges',
    JourneyGameDevelopmentTextChallenges: `Mastering Object-Oriented Programming (OOP) was pivotal.\n
    It's the foundation of modern software design, and mastering it was essential for me to progress.\n
    Learning and implementing design patterns improved my programming efficiency significantly.\n
    Architectural Planning was crucial for the successful development of my projects.\n
    In-Depth Documentation is a multifaceted practice that goes beyond just writing docstrings, including creating thorough README files,\n
    architectural diagrams, and using progress tracking tools.\n
    Implementing auto formatting and linting tools was a game-changer for ensuring high code quality.\n
    Understanding and utilizing type hinting in detail helped me write clearer and more error-proof code.`,

    AUniqueApproachHeading: 'A Unique Approach',
    JourneyGameDevelopmentTextUniqueApproach: `One of the most distinctive aspects of my journey has been the challenge of single-handedly managing multiple roles in the game development process.\n
    Wearing multiple hats, I have delved into not just game development, but also game design, graphics, web design, engineering, and architectural planning.\n
    This multi-faceted approach has not only broadened my skill set but also given me a unique perspective on how each aspect of a game comes together to create a cohesive and engaging experience.`,

    LookingAheadHeading: 'Looking Ahead',
    LookingAheadSubHeading0: 'Initial Launch: Building the Foundation',
    LookingAheadText0: `As we continue to develop and refine our gaming experience, our immediate focus is on the launch of version 0.1.\n
    This initial release, targeted for our dedicated beta testers, is a crucial step in our development process.\n
    It's not just about unveiling the game's core features; it's about building a foundation that is shaped by player feedback and our vision for the game.`,

    LookingAheadSubHeading1: 'Towards Version 1.0: Shaping Our Vision',
    LookingAheadText1: `Our journey doesn't end with version 0.1.\n
    The insights and suggestions we gather from our beta testers will be invaluable as we progress towards the release of version 1.0 - our first official launch.\n
    This milestone will mark the fruition of our initial vision, but more importantly, it will open up new avenues for growth and improvement.`,

    LookingAheadSubHeading2: 'Continuous Improvement: Post-Launch Plans',
    LookingAheadText2: `Post-launch, our commitment to our players remains steadfast.\n
    We plan to roll out regular updates focused on refining gameplay, enhancing features, and fixing any bugs that arise.\n
    Our goal is to create a dynamic gaming experience that evolves with its community.`,

    LookingAheadSubHeading3: 'Expanding Horizons: The Promise of Version 2.0',
    LookingAheadText3: `Looking further into the horizon, we are excited about the possibilities of version 2.0.\n
    Envisioned features for this major update include multiplayer capabilities, thrilling dungeons, and perhaps even an auction house to add depth to the in-game economy.\n
    These additions are designed to enrich the player experience and bring new layers of engagement and interaction.`,

    LookingAheadSubHeading4: 'Beyond Gaming: Venturing into Merchandise',
    LookingAheadText4: `Beyond the game itself, we are exploring the realm of merchandise.\n
    Imagine bringing a piece of the game's world into your own, with items that resonate with the adventures and stories created within our game.\n
    Our journey is ongoing, and we are thrilled to have you with us.\n
    Stay tuned for more updates, and thank you for being a part of our game's evolving story.`
  }
};

export default en;
