/**
 * AboutUs.js - A component displaying the "About Us" section with personal introduction,
 * journey in game development, and future plans.
 */

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LinkIcon from '@mui/icons-material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Fab,
  Grid,
  Link,
  Paper,
  Typography
} from '@mui/material';
import React, { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ImageMe from '../_files/ImageMe.jpg';
import { gameDevelopmentJourney, lookingAhead } from '../data/aboutUs';

/**
 * AboutUs - Displays the "About Us" section with personal introduction,
 * journey in game development, and future plans.
 *
 * @component
 * @returns {JSX.Element} A container with cards and content about the author.
 */
export default function AboutUs() {
  const { t } = useTranslation();

  /**
   * Scrolls to the "About Me" section smoothly when invoked.
   *
   * @function handleClick
   * @returns {void}
   */
  const handleClick = useCallback(() => {
    const anchor = document.querySelector('#AboutMe');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return (
    <Container maxWidth="lg">
      <Paper
        elevation={4}
        sx={{
          my: 4,
          p: 3,
          borderRadius: 2,
          backgroundColor: 'background.default',
          backgroundImage: 'none',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
        }}>
        <Box my={4} id="AboutMe">
          <Typography variant="h3" component="h1" gutterBottom color="primary.main">
            {t('AboutMe')}
          </Typography>

          {/* Personal Introduction Section */}
          <Card
            sx={{
              backgroundColor: 'background.paper',
              color: 'primary.paper',
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
            }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    mt: 4,
                    ml: 2,
                    mr: 2,
                    borderRadius: '10px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    overflow: 'hidden',
                    padding: '3px',
                    background:
                      'radial-gradient(circle, rgb(255, 215, 0) 0%, rgb(238, 198, 0) 25%, rgb(218, 165, 32) 50%, rgb(184, 134, 11) 75%)'
                  }}>
                  <CardMedia
                    sx={{
                      borderRadius: '10px',
                      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
                    }}
                    component="img"
                    alt="Peter Stueben"
                    height="300"
                    image={ImageMe}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <CardContent sx={{ mr: 1, ml: 1, mt: 1 }}>
                  <Typography variant="h4" component="h2">
                    Peter Stueben
                  </Typography>
                  {t('IntroText')
                    .split('\n')
                    .map((paragraph, index) => (
                      <Typography
                        key={index}
                        variant="body1"
                        paragraph
                        sx={{ textAlign: 'left', fontSize: '20px' }}>
                        {paragraph}
                      </Typography>
                    ))}
                  <Link
                    href="http://www.linkedin.com/in/peter-stueben-03179b1bb"
                    color="inherit"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      textDecoration: 'none',
                      '&:hover': { color: 'secondary.main' },
                      fontSize: '20px'
                    }}>
                    <Box display="flex" alignItems="center" mb={1}>
                      <LinkedInIcon sx={{ mr: 1 }} />
                      <Typography variant="body1" sx={{ fontSize: '20px' }}>
                        LinkedIn
                      </Typography>
                    </Box>
                  </Link>
                  <Link
                    href="https://www.xing.com/profile/Peter_Stueben2"
                    color="inherit"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      textDecoration: 'none',
                      '&:hover': { color: 'secondary.main' },
                      fontSize: '20px'
                    }}>
                    <Box display="flex" alignItems="center">
                      <LinkIcon sx={{ mr: 1 }} />
                      <Typography variant="body1" sx={{ fontSize: '20px' }}>
                        Xing
                      </Typography>
                    </Box>
                  </Link>
                </CardContent>
              </Grid>
            </Grid>
          </Card>

          {/* Journey in Game Development Section */}
          <Card
            sx={{
              mt: 4,
              backgroundColor: 'background.paper',
              color: 'primary.paper',
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
            }}>
            <CardContent sx={{ mr: 1, ml: 1, mt: 1 }}>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                className="hyphenate">
                {t('JourneyGameDevelopmentHeading')}
              </Typography>
              {gameDevelopmentJourney.map(({ heading, text }, index) => (
                <Fragment key={index}>
                  <Typography variant="h5" component="h3" sx={{ mt: 5 }}>
                    {t(heading)}
                  </Typography>
                  {t(text)
                    .split('\n')
                    .map((paragraph, i) => (
                      <Typography
                        key={i}
                        variant="body1"
                        paragraph
                        sx={{ textAlign: 'left', fontSize: '20px' }}>
                        {paragraph}
                      </Typography>
                    ))}
                </Fragment>
              ))}
            </CardContent>
          </Card>

          {/* Looking Ahead Section */}
          <Card
            sx={{
              mt: 4,
              backgroundColor: 'background.paper',
              color: 'primary.paper',
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
            }}>
            <CardContent sx={{ mr: 1, ml: 1, mt: 1 }}>
              <Typography variant="h4" component="h2" gutterBottom>
                {t('LookingAheadHeading')}
              </Typography>
              {lookingAhead.map(({ heading, text }, index) => (
                <Fragment key={index}>
                  <Typography variant="h5" component="h3" sx={{ mt: 5 }}>
                    {t(heading)}
                  </Typography>
                  {t(text)
                    .split('\n')
                    .map((paragraph, i) => (
                      <Typography
                        key={i}
                        variant="body1"
                        paragraph
                        sx={{ textAlign: 'left', fontSize: '20px' }}>
                        {paragraph}
                      </Typography>
                    ))}
                </Fragment>
              ))}
            </CardContent>
          </Card>
        </Box>
      </Paper>
      <Fab
        color="secondary"
        size="small"
        aria-label={t('Upwards')}
        onClick={handleClick}
        sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        <KeyboardArrowUpIcon />
      </Fab>
    </Container>
  );
}
