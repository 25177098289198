// AboutTheGame.js
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Card,
  CardContent,
  Container,
  Fab,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  gameDescriptionData,
  keyFeaturesData,
  requirementsData,
  storylineSettingData
} from '../data/aboutTheGame';

/**
 * TabPanel is a functional component that displays the content of a tab panel.
 * It renders its children only if the value matches the index.
 *
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - The content to render inside the tab panel.
 * @param {number} props.value - The currently selected tab index.
 * @param {number} props.index - The index of this tab panel.
 * @returns {JSX.Element} The rendered tab panel content.
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

/**
 * AboutTheGame is the main component that renders the "About the Game" page.
 * It includes sections for game description, storyline, key features, and system requirements.
 *
 * @returns {JSX.Element} The rendered "About the Game" page.
 */
export default function AboutTheGame() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  /**
   * Scrolls to the "Game Description" section when the Fab button is clicked.
   */
  const handleClick = useCallback(() => {
    const anchor = document.querySelector('#GameDescription');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  /**
   * Handles changes in the selected tab.
   *
   * @param {React.ChangeEvent} event - The event object.
   * @param {number} newValue - The new tab index.
   */
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Paper
        elevation={4}
        sx={{
          my: 4,
          p: 3,
          borderRadius: 2,
          backgroundColor: 'background.default',
          backgroundImage: 'none',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
        }}>
        <Box my={4}>
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            color="primary.main"
            id="GameDescription">
            {t('AboutTheGame')}
          </Typography>

          {/* Game Description Section */}
          <Card
            sx={{
              my: 4,
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
              position: 'relative',
              backgroundColor: 'background.paper',
              color: 'primary.paper'
            }}>
            <CardContent sx={{ mr: 1, ml: 1, mt: 1 }}>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                className="hyphenate">
                {t('GameDescriptionHeading')}
              </Typography>
              {gameDescriptionData.map(({ heading, text }, index) => (
                <Fragment key={index}>
                  <Typography variant="h5" component="h3" sx={{ mt: 5 }}>
                    {t(heading)}
                  </Typography>
                  {t(text)
                    .split('\n')
                    .map((paragraph, i) => (
                      <Typography
                        key={i}
                        variant="body1"
                        paragraph
                        sx={{ textAlign: 'left', fontSize: '20px' }}>
                        {paragraph}
                      </Typography>
                    ))}
                </Fragment>
              ))}
            </CardContent>
          </Card>

          {/* Storyline Section */}
          <Card
            sx={{
              my: 4,
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
              position: 'relative',
              backgroundColor: 'background.paper',
              color: 'primary.paper'
            }}>
            <CardContent>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                className="hyphenate">
                {t('Storyline')}
              </Typography>
              {storylineSettingData.map(({ heading, text }, index) => (
                <Fragment key={index}>
                  <Typography variant="h5" component="h3" sx={{ mt: 5 }}>
                    {t(heading)}
                  </Typography>
                  {t(text)
                    .split('\n')
                    .map((paragraph, i) => (
                      <Typography
                        key={i}
                        variant="body1"
                        paragraph
                        sx={{ textAlign: 'left', fontSize: '20px' }}>
                        {paragraph}
                      </Typography>
                    ))}
                </Fragment>
              ))}
            </CardContent>
          </Card>

          {/* Key Features Section */}
          <Card
            sx={{
              my: 4,
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
              position: 'relative',
              backgroundColor: 'background.paper',
              color: 'primary.paper'
            }}>
            <CardContent>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                className="hyphenate">
                {t('KeyFeatures')}
              </Typography>
              {keyFeaturesData.map(({ heading, text }, index) => (
                <Fragment key={index}>
                  <Typography variant="h5" component="h3" sx={{ mt: 5 }}>
                    {t(heading)}
                  </Typography>
                  {t(text)
                    .split('\n')
                    .map((paragraph, i) => (
                      <Typography
                        key={i}
                        variant="body1"
                        paragraph
                        sx={{ textAlign: 'left', fontSize: '20px' }}>
                        {paragraph}
                      </Typography>
                    ))}
                </Fragment>
              ))}
            </CardContent>
          </Card>

          {/* System Requirements Section */}
          <Card
            sx={{
              my: 4,
              boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
              position: 'relative',
              backgroundColor: 'background.paper'
            }}>
            <CardContent>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                className="hyphenate">
                {t('SysReq')}
              </Typography>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="OS tabs"
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  sx={{
                    '.MuiTabs-indicator': { backgroundColor: 'background.default' },
                    '.MuiTab-root': {
                      textAlign: 'left',
                      padding: isMobile ? '6px 12px' : '12px 24px'
                    }
                  }}>
                  {Object.keys(requirementsData).map((os) => (
                    <Tab key={os} label={t(os)} />
                  ))}
                </Tabs>
              </Box>
              {Object.entries(requirementsData).map(([os, requirements], index) => (
                <TabPanel value={tabValue} index={index} key={os}>
                  <Grid container spacing={2} direction={isMobile ? 'column' : 'row'}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h5" component="h3">
                        {t('SysReqMin')}
                      </Typography>
                      {Object.entries(requirements.minimum).map(([key, value]) => (
                        <Typography key={key}>
                          <strong>{t(key)}:</strong> {value}
                        </Typography>
                      ))}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h5" component="h3">
                        {t('SysReqRec')}
                      </Typography>
                      {Object.entries(requirements.recommended).map(([key, value]) => (
                        <Typography key={key}>
                          <strong>{t(key)}:</strong> {value}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                </TabPanel>
              ))}
            </CardContent>
          </Card>
        </Box>
      </Paper>
      <Fab
        color="secondary"
        aria-label={t('Upwards')}
        size="small"
        onClick={handleClick}
        sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        <KeyboardArrowUpIcon />
      </Fab>
    </Container>
  );
}
