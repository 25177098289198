import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Dialog,
  DialogContent,
  ImageList,
  ImageListItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import allNewsData from '../data/news';

/**
 * News is a React functional component that renders a list of news items in an accordion format.
 * It uses the useTranslation hook for internationalization and manages the state of expanded
 * accordion items. The news data is retrieved from a utility function.
 *
 * @returns {JSX.Element} The rendered news component.
 */
export default function News() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  /**
   * Handles the change event for each accordion item.
   * Updates the state to track which accordion panel is currently expanded.
   *
   * @param {string} panel - The identifier of the panel.
   * @returns {function} A function that takes the event and isExpanded boolean.
   */
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  /**
   * Handles the click on an image to open it in the Dialog.
   *
   * @param {string} image - The image source to display.
   */
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  /**
   * Handles the click on an image to open it in the Dialog.
   *
   * @param {string} image - The image source to display.
   */
  const handleClose = () => {
    setSelectedImage(null);
  };

  const newsData = allNewsData(t);

  return (
    <Container maxWidth="lg">
      <Paper
        elevation={4}
        sx={{
          my: 4,
          p: 3,
          borderRadius: 2,
          backgroundColor: 'background.default',
          backgroundImage: 'none',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
        }}>
        <Box my={4}>
          <Typography variant="h3" component="h1" gutterBottom color="primary.main">
            {t('News')}
          </Typography>
          {newsData.map((newsItem) => (
            <Accordion
              key={newsItem.id}
              sx={{
                backgroundImage: 'none',
                boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
              }}
              expanded={expanded === newsItem.id}
              onChange={handleChange(newsItem.id)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: 'primary.paper' }} />}
                aria-controls={`${newsItem.id}bh-content`}
                id={`${newsItem.id}bh-header`}>
                <Typography
                  sx={{
                    color: 'primary.paper',
                    width: '66%',
                    flexShrink: 0,
                    fontSize: '20px'
                  }}>
                  {newsItem.title}
                </Typography>
                <Typography sx={{ color: 'primary.paper', fontSize: '20px' }}>
                  {newsItem.date}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {newsItem.content.split('\n').map((paragraph, index) => (
                  <Typography
                    key={index}
                    sx={{
                      justifyContent: 'left',
                      color: 'primary.paper',
                      fontSize: '20px',
                      marginBottom: '16px'
                    }}>
                    {paragraph}
                  </Typography>
                ))}
                {/* Conditionally render images */}
                {newsItem.images && (
                  <ImageList
                    cols={isMobile ? 1 : 2}
                    gap={16}
                    sx={{
                      marginTop: '16px',
                      justifyContent: 'center',
                      flexWrap: 'wrap'
                    }}>
                    {newsItem.images.map((image, index) => (
                      <ImageListItem key={index}>
                        <Box
                          sx={{
                            position: 'relative',
                            display: 'inline-flex',
                            alignItems: 'center',
                            padding: isMobile ? '2px' : '4px',
                            background:
                              'radial-gradient(circle, rgb(255, 215, 0) 0%, rgb(238, 198, 0) 25%, rgb(218, 165, 32) 50%, rgb(184, 134, 11) 75%)',
                            borderRadius: isMobile ? '5px' : '10px'
                          }}>
                          <img
                            src={image}
                            alt={`News image ${index + 1}`}
                            style={{
                              cursor: 'pointer',
                              borderRadius: isMobile ? '5px' : '10px',
                              width: '100%',
                              height: 'auto'
                            }}
                            onClick={() => handleImageClick(image)}
                          />
                        </Box>
                      </ImageListItem>
                    ))}
                  </ImageList>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Paper>

      {/* Dialog for enlarged image */}
      <Dialog
        open={!!selectedImage}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{
          sx: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}>
        <DialogContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0
          }}>
          {selectedImage && (
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: isMobile ? '2px' : '4px',
                background:
                  'radial-gradient(circle, rgb(255, 215, 0) 0%, rgb(238, 198, 0) 25%, rgb(218, 165, 32) 50%, rgb(184, 134, 11) 75%)',
                borderRadius: isMobile ? '5px' : '20px',
                maxWidth: '90%',
                maxHeight: '90vh'
              }}>
              <img
                src={selectedImage}
                alt="Enlarged view"
                style={{
                  borderRadius: isMobile ? '5px' : '15px',
                  width: '100%',
                  height: 'auto'
                }}
              />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
}
