import ImageAuroria00 from '../_files/news/auroria/auroria00.png';
import ImageAuroria01 from '../_files/news/auroria/auroria01.png';
import ImageAuroria02 from '../_files/news/auroria/auroria02.png';
import ImageAuroria03 from '../_files/news/auroria/auroria03.png';
import ImageVerdante00 from '../_files/news/verdante/verdante00.png';
import ImageVerdante01 from '../_files/news/verdante/verdante01.png';
import ImageVerdante02 from '../_files/news/verdante/verdante02.png';
import ImageVerdante03 from '../_files/news/verdante/verdante03.png';

// newsData.js
const allNewsData = (t) => [
  {
    id: 'news0',
    title: t('NewsHeading0'),
    date: 'Jan 21, 2024',
    content: t('NewsContent0'),
    images: null
  },
  {
    id: 'news1',
    title: t('NewsHeading1'),
    date: 'Apr 31, 2024',
    content: t('NewsContent1'),
    images: [ImageAuroria00, ImageAuroria01, ImageAuroria02, ImageAuroria03]
  },
  {
    id: 'news2',
    title: t('NewsHeading2'),
    date: 'Jul 31, 2024',
    content: t('NewsContent2'),
    images: [ImageVerdante00, ImageVerdante01, ImageVerdante02, ImageVerdante03]
  }
];

export default allNewsData;
