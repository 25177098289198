const gameDevelopmentJourney = [
  { heading: 'TheBeginningHeading', text: 'JourneyGameDevelopmentTextBeginning' },
  { heading: 'JourneyGameDevHeading0', text: 'JourneyGameDevText0' },
  { heading: 'JourneyGameDevHeading1', text: 'JourneyGameDevText1' },
  { heading: 'JourneyGameDevHeading2', text: 'JourneyGameDevText2' },
  { heading: 'JourneyGameDevHeading3', text: 'JourneyGameDevText3' },
  { heading: 'JourneyGameDevHeading4', text: 'JourneyGameDevText4' },
  {
    heading: 'OvercomingChallengesHeading',
    text: 'JourneyGameDevelopmentTextChallenges'
  },
  {
    heading: 'AUniqueApproachHeading',
    text: 'JourneyGameDevelopmentTextUniqueApproach'
  }
];

const lookingAhead = [
  { heading: 'LookingAheadSubHeading0', text: 'LookingAheadText0' },
  { heading: 'LookingAheadSubHeading1', text: 'LookingAheadText1' },
  { heading: 'LookingAheadSubHeading2', text: 'LookingAheadText2' },
  { heading: 'LookingAheadSubHeading3', text: 'LookingAheadText3' },
  { heading: 'LookingAheadSubHeading4', text: 'LookingAheadText4' }
];

export { gameDevelopmentJourney, lookingAhead };
