const requirementsData = {
  macOS: {
    minimum: {
      OS: 'macOS 10.13 or newer',
      Processor: '2-core 1.7 GHz or better',
      Memory: '4 GB RAM',
      Graphics: 'Radeon Pro 560X or better',
      Storage: '3 GB available space'
    },
    recommended: {
      OS: 'macOS 10.13 or newer',
      Processor: '4-core 2.9 GHz or better',
      Memory: '16 GB RAM',
      Graphics: 'Radeon Pro 5500 XT or eGPU',
      Storage: '5 GB available space'
    }
  },
  Windows: {
    minimum: {
      OS: 'Windows 10',
      Processor: '2-core 2 GHz or better',
      Memory: '4 GB RAM',
      Graphics: 'NVIDIA GTX 670 or AMD Radeon HD 7870',
      Storage: '3 GB available space'
    },
    recommended: {
      OS: 'Windows 10',
      Processor: '4-core 3 GHz or better',
      Memory: '16 GB RAM',
      Graphics: 'NVIDIA GTX 1060 or AMD Radeon RX 480',
      Storage: '5 GB available space'
    }
  },
  Linux: {
    minimum: {
      OS: 'Ubuntu 18.04',
      Processor: '2-core 2 GHz or better',
      Memory: '4 GB RAM',
      Graphics: 'NVIDIA GTX 670 or AMD Radeon HD 7870',
      Storage: '3 GB available space'
    },
    recommended: {
      OS: 'Ubuntu 18.04',
      Processor: '4-core 3 GHz or better',
      Memory: '16 GB RAM',
      Graphics: 'NVIDIA GTX 1060 or AMD Radeon RX 480',
      Storage: '5 GB available space'
    }
  }
};

const gameDescriptionData = [
  {
    heading: 'GameDescriptionSubHeading0',
    text: 'GameDescriptionText0'
  },
  {
    heading: 'GameDescriptionSubHeading1',
    text: 'GameDescriptionText1'
  },
  {
    heading: 'GameDescriptionSubHeading2',
    text: 'GameDescriptionText2'
  },
  {
    heading: 'GameDescriptionSubHeading3',
    text: 'GameDescriptionText3'
  },
  {
    heading: 'GameDescriptionSubHeading4',
    text: 'GameDescriptionText4'
  },
  {
    heading: 'GameDescriptionSubHeading5',
    text: 'GameDescriptionText5'
  },
  {
    heading: 'GameDescriptionSubHeading6',
    text: 'GameDescriptionText6'
  }
];

const storylineSettingData = [
  {
    heaading: 'StorylineHeading',
    text: 'StorylineText'
  }
];

const keyFeaturesData = [
  {
    heading: 'KeyFeatureSubHeading0',
    text: 'KeyFeatureText0'
  },
  {
    heading: 'KeyFeatureSubHeading1',
    text: 'KeyFeatureText1'
  },
  {
    heading: 'KeyFeatureSubHeading2',
    text: 'KeyFeatureText2'
  },
  {
    heading: 'KeyFeatureSubHeading3',
    text: 'KeyFeatureText3'
  },
  {
    heading: 'KeyFeatureSubHeading4',
    text: 'KeyFeatureText4'
  },
  {
    heading: 'KeyFeatureSubHeading5',
    text: 'KeyFeatureText5'
  },
  {
    heading: 'KeyFeatureSubHeading6',
    text: 'KeyFeatureText6'
  }
];

export { gameDescriptionData, keyFeaturesData, requirementsData, storylineSettingData };
