import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import {
  Box,
  Container,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ImageGame00 from '../_files/home/game00.png';
import ImageGame01 from '../_files/home/game01.png';
import ImageGame02 from '../_files/home/game02.png';
import ImageGame03 from '../_files/home/game03.png';
import ImageGame04 from '../_files/home/game04.png';
import ImageGame05 from '../_files/home/game05.png';
import ImageGame06 from '../_files/home/game06.png';
import ImageGame07 from '../_files/home/game07.png';
import ImageGame08 from '../_files/home/game08.png';
import CompanyLogo from '../_files/kings-games-logo-middle.png';

export default function Home() {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  const images = [
    ImageGame00,
    ImageGame01,
    ImageGame02,
    ImageGame03,
    ImageGame04,
    ImageGame05,
    ImageGame06,
    ImageGame07,
    ImageGame08
  ];

  const subtitles = [
    t('Subtitle0'),
    t('Subtitle1'),
    t('Subtitle2'),
    t('Subtitle3'),
    t('Subtitle4'),
    t('Subtitle5'),
    t('Subtitle6'),
    t('Subtitle7'),
    t('Subtitle8')
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  /**
   * Handles the click event on the arrow icons to navigate through the carousel.
   * Updates the active image index and triggers the fading effect.
   *
   * @param {'next' | 'prev'} direction - The direction of the carousel navigation, either 'next' or 'prev'.
   */
  const handleArrowClick = (direction) => {
    setIsFading(true); // Start fading animation
    setActiveIndex((prev) =>
      direction === 'next'
        ? (prev + 1) % images.length
        : (prev - 1 + images.length) % images.length
    );
    setTimeout(() => setIsFading(false), 1000); // Reset fading state after 1 second
  };

  /**
   * Automatically moves the carousel forward every 5 seconds.
   */
  useEffect(() => {
    const timer = setInterval(() => {
      setIsFading(true); // Start fading animation
      setActiveIndex((prev) => (prev + 1) % images.length);
      setTimeout(() => setIsFading(false), 1000); // Reset fading state after 1 second
    }, 5000); // 5 seconds interval
    return () => clearInterval(timer); // Cleanup the timer
  }, [images.length]);

  return (
    <Container maxWidth="lg">
      <Paper
        elevation={4}
        sx={{
          my: 4,
          p: 3,
          mt: 8,
          borderRadius: 2,
          backgroundColor: 'background.default',
          backgroundImage: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'
        }}>
        <Box
          component="img"
          sx={{
            height: 300,
            width: 'auto',
            maxWidth: '100%'
          }}
          alt="Company Logo"
          src={CompanyLogo}
        />
      </Paper>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100%', width: '100%' }}>
        <Paper
          elevation={4}
          sx={{
            my: 4,
            p: 3,
            borderRadius: 2,
            backgroundColor: 'background.default',
            backgroundImage: 'none',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
            width: '100%'
          }}>
          <Box
            my={4}
            textAlign="center"
            sx={{
              position: 'relative',
              '&:hover .controls': {
                opacity: isMobile ? 1 : 1 // Show controls when hovering on desktop
              }
            }}>
            <Typography variant="h5" gutterBottom color="primary.main" sx={{ mb: 4 }}>
              {t('Heading0')}
            </Typography>
            <Box
              sx={{
                position: 'relative',
                height: {
                  xs: '270px',
                  sm: '300px',
                  md: '450px',
                  lg: '510px'
                },
                mt: 5,
                width: '100%',
                overflow: 'hidden'
              }}>
              {images.map((image, index) => (
                <Box
                  key={index}
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    opacity: index === activeIndex ? 1 : 0,
                    transition: isFading ? 'opacity 1s ease-in-out' : 'none' // Smooth transition when fading
                  }}>
                  <img
                    src={image}
                    alt={`Slide ${index}`}
                    style={{
                      display: 'block',
                      maxWidth: '100%',
                      maxHeight: '100%',
                      borderRadius: isMobile ? '5px' : '10px',
                      boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)'
                    }}
                  />
                </Box>
              ))}

              {/* Arrows */}
              <IconButton
                onClick={() => handleArrowClick('prev')}
                aria-label={t('PreviousSlide')}
                className="controls"
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  zIndex: 2,
                  opacity: isMobile ? 1 : 0,
                  transition: 'opacity 0.3s',
                  '&:hover': {
                    opacity: 1
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: isMobile ? '25px' : '50px'
                  }
                }}>
                <ArrowCircleLeftIcon color="primary" />
              </IconButton>

              <IconButton
                onClick={() => handleArrowClick('next')}
                aria-label={t('NextSlide')}
                className="controls"
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  zIndex: 2,
                  opacity: isMobile ? 1 : 0, // Initially hidden on desktop
                  transition: 'opacity 0.3s',
                  '&:hover': {
                    opacity: 1
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: isMobile ? '25px' : '50px'
                  }
                }}>
                <ArrowCircleRightIcon color="primary" />
              </IconButton>

              {/* Dots */}
              <Box
                className="controls"
                sx={{
                  position: 'absolute',
                  bottom: 10,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                  opacity: isMobile ? 1 : 0, // Initially hidden on desktop
                  transition: 'opacity 0.3s'
                }}>
                {images.map((_, index) => (
                  <IconButton
                    key={index}
                    onClick={() => setActiveIndex(index)}
                    sx={{
                      backgroundColor:
                        activeIndex === index ? 'primary.main' : 'grey.400',
                      width: activeIndex === index ? 16 : 12,
                      height: activeIndex === index ? 16 : 12,
                      borderRadius: '50%',
                      transition: 'all 0.3s ease',
                      border: activeIndex === index ? '2px solid white' : 'none',
                      '&:hover': {
                        backgroundColor:
                          activeIndex === index ? 'primary.dark' : 'grey.500'
                      }
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Box>
          <Typography
            variant="subtitle1"
            gutterBottom
            color="primary.main"
            sx={{ mt: 2, textAlign: 'center' }}>
            {subtitles[activeIndex]}
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
}
